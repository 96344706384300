import React, { createContext, useMemo, useContext } from 'react';
import { initializeApp } from 'firebase/app';
import 'firebase/firestore';
import firebaseConfig from './config';

const FirebaseContext = createContext(null);

export function FirebaseProvider({ children }) {
  const firebase = useMemo(() => initializeApp(firebaseConfig), []);

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
}

export default function useFirebase() {
  const firebase = useContext(FirebaseContext);

  if (!firebase) {
    throw new Error('Unexpected use of useFirebase outside of its provider');
  }

  return firebase;
}
