import React, { lazy, Suspense } from 'react';
import { Loading } from '../../components';
import { useDocumentDataWithSetter } from '../../lib';

const TYPES = {
  default: lazy(() => import('./types/Schub')),
  ikonphon: lazy(() => import('./types/Ikonphon.js')),
};

export function MusicFinder({
  match: {
    url,
    params: { id },
  },
}) {
  const [doc, loading, error] = useDocumentDataWithSetter('queries', id);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }

  if (!doc) {
    return <p>404</p>;
  }

  const Comp = TYPES[doc.type] || TYPES.default;

  return (
    <Suspense fallback={<Loading />}>
      <Comp doc={doc} baseUrl={url.replace(/\/$/, '')} />
    </Suspense>
  );
}
