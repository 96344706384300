import React, { useMemo, lazy, Suspense } from 'react';
import { useCollection } from '../../lib';
import { Loading } from '../../components';
import NewQuery from './NewQuery';

const TYPES = {
  default: lazy(() => import('./types/Schub')),
  ikonphon: lazy(() => import('./types/Ikonphon')),
};

function useTypedDocData(value) {
  return useMemo(
    () =>
      value
        ? value.docs
            .filter((snapshot) => snapshot.exists)
            .map((snapshot) => ({ id: snapshot.id, ...snapshot.data() }))
            .reduce(
              (memo, doc) => ({
                ...memo,
                [doc.type || 'default']: (
                  memo[doc.type || 'default'] || []
                ).concat(doc),
              }),
              {},
            )
        : null,
    [value],
  );
}

export function QueryList({ match: { url } }) {
  const [value, loading, error, queries] = useCollection('queries');
  const docsByType = useTypedDocData(value);

  if (error) {
    return <p>Error {error}</p>;
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <h1>Anfragen</h1>
      <Suspense fallback={<Loading />}>
        {Object.entries(docsByType).map(([type, docs]) => {
          const Comp = TYPES[type] || TYPES.default;
          return <Comp key={type} docs={docs} />;
        })}
      </Suspense>
      <NewQuery queries={queries} />
    </>
  );
}
