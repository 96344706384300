import React, { useState, useEffect } from 'react';

export function Loading() {
  const [length, setProgress] = useState(0);
  useEffect(() => {
    let i = 0;
    const next = () =>
      setTimeout(() => {
        setProgress(i++);
        if (i > 5) {
          i = 0;
        }
        t = next();
      }, 300);
    let t = next();

    return () => clearTimeout(t);
  }, []);

  return <p>Loading{Array.from({ length }, () => '.').join('')}</p>;
}
