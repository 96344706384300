import { useMemo, useCallback } from 'react';
import throttle from 'lodash.throttle';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import useFirebase from './useFirebase';

export function useDocumentDataWithSetter(collectionName, id) {
  const firebase = useFirebase();
  const doc = useMemo(
    () =>
      firebase
        .firestore()
        .collection(collectionName)
        .doc(id),
    [firebase, collectionName, id],
  );
  const [data, loading, error] = useDocumentData(doc);
  const set = useCallback((newData) => doc.set(newData, { merge: true }), [
    doc,
  ]);

  const setThrottled = useCallback(throttle(set, 300), [doc]);
  const docWithSetter = useMemo(
    () =>
      loading || !data
        ? null
        : {
            ...data,
            set,
            setThrottled,
          },
    [set, setThrottled, data, loading],
  );

  return [docWithSetter, loading, error];
}
