import 'firebase/storage';
import { useMemo, useEffect, useState } from 'react';
import useFirebase from './useFirebase';

export function useLibrary(signal = null) {
  const firebase = useFirebase();
  const [files, setFiles] = useState(null);
  const ref = useMemo(
    () =>
      firebase
        .storage()
        .ref()
        .child(`library`),
    [firebase],
  );
  useEffect(() => {
    let canceled = false;
    ref
      .listAll()
      .then(({ items }) => {
        if (!canceled) {
          setFiles(items);
        }
      })
      .catch(console.error);

    return () => (canceled = true);
  }, [ref, signal]);

  return files;
}
