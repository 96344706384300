import styled from 'styled-components';

export const Table = styled.table`
  border-color: white;
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
  thead tr {
    border-bottom: 2px solid white;
  }
  th {
    padding: 0.5em;
    text-align: left;
    &:first-child {
      padding-left: 0;
    }
  }
  tr {
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  }
  td {
    padding-left: 0;
    padding: 0.5em;
    &:last-child {
      text-align: right;
    }
  }
`;
