import useFirebase from './useFirebase';
import { useCollection as useC } from 'react-firebase-hooks/firestore';
import { useMemo } from 'react';

export function useCollection(collectionName, options) {
  const firebase = useFirebase();
  const collection = useMemo(
    () => firebase.firestore().collection(collectionName),
    [firebase, collectionName],
  );

  return [...useC(collection, options), collection];
}
