import styled from 'styled-components';

export const Input = styled.input`
  box-sizing: border-box;
  font: inherit;
  border-radius: 0;
  display: flex;
  width: 100%;
  margin-bottom: 1em;
  border: none;
  color: white;
  border-bottom: 2px solid white;
  background: rgba(255, 255, 255, 0.05);
  padding: 1em;
  transition: background 120ms ease-out;

  &:focus {
    background: rgba(255, 255, 255, 0.25);

    outline: none;
  }
`;
