export default {
  projectId: 'music-sense-5c63d',
  appId: '1:774865488709:web:34640c2cee46918d018c2c',
  databaseURL: 'https://music-sense-5c63d.firebaseio.com',
  storageBucket: 'music-sense-5c63d.appspot.com',
  locationId: 'europe-west3',
  apiKey: 'AIzaSyBNc7Os9WOLmjMKwe8xfcHj7jBVoRxaqcE',
  authDomain: 'music-sense-5c63d.firebaseapp.com',
  messagingSenderId: '774865488709',
};
