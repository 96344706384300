import { useRouteMatch } from 'react-router-dom';

export function useSteps(steps, baseUrl) {
  const {
    params: { stepName = steps[0].name },
  } = useRouteMatch(`${baseUrl}/:stepName?`);
  const step = steps.find(({ name }) => name === stepName);

  if (!step) {
    return [];
  }

  const nextStep = steps[steps.indexOf(step) + 1];
  const nextStepUrl = nextStep && `${baseUrl}/${nextStep.name}`;

  return [step, nextStepUrl, nextStep];
}

export function createStep(name, rest) {
  return {
    name,
    set(doc, value) {
      doc.set({ [name]: value });
    },
    get(doc) {
      return doc[name];
    },
    ...rest,
  };
}
