import { ReactComponent as Heavy } from './illustrations/heavy.svg';
import { ReactComponent as Loud } from './illustrations/natural.svg';
import { ReactComponent as Natural } from './illustrations/natural.svg';
import { ReactComponent as OnClock } from './illustrations/onclock.svg';
import { ReactComponent as Quiet } from './illustrations/quiet.svg';
import { ReactComponent as Rainy } from './illustrations/rainy.svg';
import { ReactComponent as Snowy } from './illustrations/snowy.svg';
import { ReactComponent as Sunny } from './illustrations/sunny.svg';
import { ReactComponent as Waiting } from './illustrations/waiting.svg';
import vars from '../vars.json';
import createIconSelect from '../createIconSelect';

export const ENVIRONMENTS = [
  { color: vars.colors.yellow, Icon: Sunny, name: 'sunny' },
  { color: vars.colors.darkblue, Icon: Rainy, name: 'rainy' },
  { color: vars.colors.skyblue, Icon: Snowy, name: 'snowy' },
  { color: vars.colors.skyblue, Icon: Natural, name: 'natural' },
  { color: vars.colors.lavender, Icon: Quiet, name: 'quiet' },
  { color: vars.colors.yellow, Icon: Loud, name: 'loud' },
  { color: vars.colors.darkred, Icon: Heavy, name: 'heavy' },
  { color: vars.colors.petrol, Icon: Waiting, name: 'waiting' },
  { color: vars.colors.strongblue, Icon: OnClock, name: 'on-clock' },
];

export const EnvironmentSelect = createIconSelect(ENVIRONMENTS, 'environment');
