import { ReactComponent as Confused } from './illustrations/confused.svg';
import { ReactComponent as Happy } from './illustrations/happy.svg';
import { ReactComponent as Laugh } from './illustrations/laugh.svg';
import { ReactComponent as Love } from './illustrations/love.svg';
import { ReactComponent as Lovestruck } from './illustrations/lovestruck.svg';
import { ReactComponent as Money } from './illustrations/money.svg';
import { ReactComponent as Sad } from './illustrations/sad.svg';
import { ReactComponent as Smile } from './illustrations/smile.svg';
import { ReactComponent as Twisted } from './illustrations/twisted.svg';
import vars from '../vars.json';
import createIconSelect from '../createIconSelect';

export const CHARACTERS = [
  { color: vars.colors.yellow, Icon: Smile, name: 'smile' },
  { color: vars.colors.red, Icon: Love, name: 'love' },
  { color: vars.colors.blue, Icon: Laugh, name: 'laugh' },
  { color: vars.colors.pidgeon, Icon: Sad, name: 'sad' },
  { color: vars.colors.teal, Icon: Twisted, name: 'twisted' },
  { color: vars.colors.mustard, Icon: Money, name: 'money' },
  { color: vars.colors.orangeyellow, Icon: Happy, name: 'happy' },
  { color: vars.colors.darkred, Icon: Lovestruck, name: 'lovestruck' },
  { color: vars.colors.winered, Icon: Confused, name: 'confused' },
];

export const CharacterSelect = createIconSelect(CHARACTERS, 'character');
