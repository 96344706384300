import React from 'react';
import styled from 'styled-components';

const StyledEmojiSellect = styled.div`
  position: relative;
  font-size: 3em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  grid-gap: 16px;
`;

const StyledAnswerWrap = styled.div`
  position: relative;
  padding-bottom: 100%;
`;

const StyledLabel = styled.label`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  opacity: 0.5;
  transition: opacity 120ms ease-out;

  border: 2px solid white;
`;

const StyledInput = styled.input`
  opacity: 0;
  position: absolute;
  &:checked ~ ${StyledLabel} {
    background: rgba(255, 255, 255, 0.1);
    opacity: 1;
  }
`;

const POSSIBLE_ANSWERS = ['😊', '😔', '😲', '🤣', '🥰', '😡', '😭', '🤯', '😐'];

export const EmojiSelect = ({ name = 'emojiselect', onChange, value }) => {
  return (
    <StyledEmojiSellect>
      {POSSIBLE_ANSWERS.map((i) => (
        <StyledAnswerWrap key={i}>
          <StyledInput
            value={i}
            id={i}
            onChange={onChange}
            name={name}
            checked={value === i}
            type="radio"
          />
          <StyledLabel htmlFor={i}>{i}</StyledLabel>
        </StyledAnswerWrap>
      ))}
    </StyledEmojiSellect>
  );
};
