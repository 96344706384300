import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useUser, useFirebase } from '../lib';
import { Button, Input, Label } from '../components';

import styled from 'styled-components';

export function Login({ match, history }) {
  const firebase = useFirebase();
  const [user, loading, error] = useUser();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(null);

  useEffect(() => {
    if (user) {
      history.push(
        match && match.params.target
          ? `/${decodeURIComponent(match.params.target)}`
          : '/',
      );
    }
  }, [match, user, history]);

  return (
    <form
      onSubmit={(ev) => {
        ev.preventDefault();
        firebase
          .auth()
          .signInWithEmailAndPassword(email, password)
          .then(() => setLoginError(null))
          .catch(setLoginError);
      }}
    >
      <Label>E-Mail</Label>
      <Input
        placeholder="name@domain.com"
        type="email"
        value={email}
        onChange={(ev) => setEmail(ev.target.value)}
      />
      <Label>Password</Label>
      <Input
        placeholder="****"
        type="password"
        value={password}
        onChange={(ev) => setPassword(ev.target.value)}
      />
      <Button type="submit">Login</Button>
      {error && <p>Error: {error}</p>}
      {loginError && <p>Error: {loginError.message}</p>}
      {loading && <p>Loading...</p>}
    </form>
  );
}

const StyledUser = styled.div`
  padding: 1em;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: auto auto;
  justify-content: flex-end;
`;

export function Logout() {
  const history = useHistory();
  const [user, loading] = useUser();
  const firebase = useFirebase();
  useEffect(() => {
    if (loading) {
      return;
    }

    let canceled = false;

    const back = () => {
      if (!canceled) {
        history.push('/');
      }
    };

    if (!user) {
      back();
      return;
    }

    firebase
      .auth()
      .signOut()
      .then(back);

    return () => {
      canceled = true;
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [user, loading]);

  return <p>Logging out...</p>;
}

export function User() {
  const [user, loading] = useUser();

  if (loading) {
    return null;
  }

  if (!user) {
    return (
      <StyledUser>
        <Link to="/login">Login</Link>
      </StyledUser>
    );
  }

  return (
    <StyledUser>
      {user.email} <Link to="/logout">Logout</Link>
    </StyledUser>
  );
}
