import React from 'react';
import styled, { css } from 'styled-components';
import vars from './vars';
import { Link } from 'react-router-dom';

export const Button = styled.button`
  font: inherit;
  font-weight: bold;

  text-decoration: none;
  text-align: center;
  border: none;
  background: ${vars.colors.blue};
  color: white;
  border-radius: 2px;
  padding: 1em;
  width: auto;
  transition: background 120ms ease-out;
  &[disabled] {
    opacity: 0.5;
  }
  &:focus {
    outline: none;
  }
  &:hover {
    background: ${vars.colors.blueLight};
  }
  ${(p) =>
    p.secondary &&
    css`
      box-shadow: inset 0 0 0px 2px white;
      background: none;
    `}
`;

function LinkButtonProxy({ disabled, secondary, ...rest }) {
  if (disabled) {
    return <button disabled {...rest} />;
  }
  return <Link {...rest} />;
}

export function LinkButton(props) {
  return <Button as={LinkButtonProxy} {...props} />;
}

export const ButtonGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;
