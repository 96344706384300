import React from 'react';
import styled from 'styled-components';

const StyledCharacterGrid = styled.div`
  display: grid;
  grid-gap: 24px;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 1em;
`;
const StyledCharacterWrap = styled.div`
  position: relative;

  padding-bottom: 100%;
`;
const StyledCharacter = styled.label`
  border-radius: 8px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.65;

  svg {
    width: 60%;
    height: 60%;
  }
`;

const StyledCharacterInput = styled.input`
  position: absolute;
  opacity: 0;
  &:checked ~ ${StyledCharacter} {
    opacity: 1;
  }
`;

export default function createIconSelect(values, type) {
  return ({ value, onChange }) => {
    return (
      <StyledCharacterGrid>
        {values.map(({ color, name, Icon }) => (
          <StyledCharacterWrap key={name}>
            <StyledCharacterInput
              type="radio"
              name="character"
              id={`${type}-${name}`}
              value={name}
              onChange={onChange}
              checked={value === name}
            />
            <StyledCharacter
              style={{ backgroundColor: color }}
              htmlFor={`${type}-${name}`}
            >
              <Icon />
            </StyledCharacter>
          </StyledCharacterWrap>
        ))}
      </StyledCharacterGrid>
    );
  };
}
