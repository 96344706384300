import React, { useMemo, useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useUser } from './firebase';
import { Loading } from '../components';

export function AdminRoute({ path, component, render, children, ...rest }) {
  const [user, loading, error] = useUser();
  const history = useHistory();
  useEffect(() => {
    if (!user && !loading && path.match(/^\//)) {
      history.push(`/login${path}`);
    } else if ((user && !user.isAdmin) || error) {
      history.push('/');
    }
  }, [path, user, loading, error, history]);
  const props = useMemo(() => {
    if (!user || !user.isAdmin) {
      return {
        ...rest,
        render: () => <Loading />,
      };
    }

    return {
      component,
      render,
      children,
      ...rest,
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [user, component, render, children, ...Object.values(rest)]);

  return <Route path={path} {...props} />;
}
