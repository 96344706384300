import React from 'react';
import styled from 'styled-components';

const StyledColorSelect = styled.label`
  background: white;

  appearance: none;
  display: flex;
  color: black;
  align-items: center;
  justify-content: center;
  font-size: 1.5em;
  font-weight: bold;
  width: 100%;
  height: 10em;
  border: none;
  border-radius: 16px;
`;

const StyledColorInput = styled.input`
  position: absolute;
  opacity: 0;
`;
export const ColorSelect = ({ value, onChange }) => {
  return (
    <StyledColorSelect style={{ backgroundColor: value }}>
      {!value && 'Wähle eine Farbe aus'}
      <StyledColorInput type="color" onChange={onChange}></StyledColorInput>
    </StyledColorSelect>
  );
};
