import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledQuery = styled.div`
  border-radius: 4px 4px 0 0;
  border: 1px solid white;
  margin-bottom: 3em;
  position: relative;
`;

const StyledName = styled.span`
  margin-right: 1ex;
`;

const StyledEmail = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;

  flex-direction: column;
  @media (max-width: 600px) {
    grid-column: 1/4;
  }
  span {
    font-weight: bold;
  }
`;

const StyledInfo = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr repeat(3, auto);
  @media (max-width: 600px) {
    grid-template-columns: repeat(3, auto);
  }
`;

const StyledQueryHead = styled.div`
  padding: 0.5em;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
  color: black;
`;

const StyledQueryBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1em 0.5em;
`;

export const QueryContainer = ({
  name,
  linkTarget = '/',
  email,
  children,
  deleteLink,
}) => {
  return (
    <StyledQuery>
      <StyledQueryHead>
        <span>
          <StyledName>{name}</StyledName>
          <Link target="_blank" to={linkTarget}>
            Link
          </Link>
        </span>
        {deleteLink}
      </StyledQueryHead>
      <StyledQueryBody>
        <StyledInfo>
          <StyledEmail>
            {email && (
              <>
                <span>E-Mail</span>
                <a href={`mailto:${email}`}>{email}</a>
              </>
            )}
          </StyledEmail>
          <>{children}</>
        </StyledInfo>
      </StyledQueryBody>
    </StyledQuery>
  );
};
