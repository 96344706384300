import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Input, Label } from '../../components';

const ArrowDown = ({ ...rest }) => (
  <svg
    width="18px"
    height="10px"
    viewBox="0 0 18 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...rest}
  >
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard"
        transform="translate(-3.000000, -10.000000)"
        fill="currentcolor"
      >
        <path
          d="M6.70710678,4.70710678 L17.2928932,15.2928932 C17.6834175,15.6834175 17.6834175,16.3165825 17.2928932,16.7071068 C17.1053568,16.8946432 16.8510029,17 16.5857864,17 L6,17 C5.44771525,17 5,16.5522847 5,16 L5,5.41421356 C5,4.86192881 5.44771525,4.41421356 6,4.41421356 C6.26521649,4.41421356 6.5195704,4.5195704 6.70710678,4.70710678 Z"
          id="Rectangle"
          transform="translate(12.000000, 10.000000) rotate(-45.000000) translate(-12.000000, -10.000000) "
        ></path>
      </g>
    </g>
  </svg>
);
const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
`;
const QueryLabel = styled(Label)`
  @media (min-width: 651px) {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }
`;
const StyledQueryInput = styled(Input)`
  @media (max-width: 650px) {
    width: 100%;
    margin-bottom: 1em;
  }
`;
const StyledSelectWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 1em;
  margin-right: 1em;

  @media (max-width: 650px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 1em;
  }
`;
const Select = styled.select`
  font: inherit;
  appearance: none;
  display: flex;
  background: transparent;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 8px;
  padding: 1em;
  padding-right: 3em;
  width: 100%;
  border: 1px solid white;
  color: white;
`;

const StyledArrowDown = styled(ArrowDown)`
  position: absolute;
  pointer-events: none;
  right: 16px;
`;
const SelectWrap = ({ children }) => (
  <StyledSelectWrap>
    {children}
    <StyledArrowDown />
  </StyledSelectWrap>
);

export default function NewQuery({ queries }) {
  const [newName, setNewName] = useState('');
  const [type, setType] = useState('default');
  const [results, setResults] = useState('default');

  return (
    <>
      <h3 style={{ marginTop: '3em' }}>Neue Anfrage erstellen</h3>
      <Wrapper>
        <StyledQueryInput
          value={newName}
          onChange={(ev) => setNewName(ev.target.value)}
          placeholder="Name der neuen Anfrage"
        />
        <QueryLabel htmlFor="type">Typ:</QueryLabel>
        <SelectWrap>
          <Select
            id="type"
            value={type}
            onChange={(ev) => setType(ev.target.value)}
          >
            <option value="default">Schubrakede</option>
            <option value="ikonphon">Ikonphon</option>
          </Select>
        </SelectWrap>
        <QueryLabel htmlFor="result">Ergebnis:</QueryLabel>
        <SelectWrap>
          <Select
            id="result"
            value={results}
            onChange={(ev) => setResults(ev.target.value)}
          >
            <option value="default">E-Mail</option>
            <option value="random">Zufällige Musik</option>
          </Select>
        </SelectWrap>
      </Wrapper>
      <br />
      <Button
        disabled={newName === ''}
        onClick={() => {
          queries.add({ name: newName, type, results });
          setNewName('');
        }}
      >
        Erstellen
      </Button>
    </>
  );
}
