import { ReactComponent as Alone } from './illustrations/alone.svg';
import { ReactComponent as Calm } from './illustrations/calm.svg';
import { ReactComponent as Chilling } from './illustrations/chilling.svg';
import { ReactComponent as Connected } from './illustrations/connected.svg';
import { ReactComponent as Dead } from './illustrations/dead.svg';
import { ReactComponent as Distant } from './illustrations/distant.svg';
import { ReactComponent as Excited } from './illustrations/excited.svg';
import { ReactComponent as Many } from './illustrations/many.svg';
import { ReactComponent as Moving } from './illustrations/moving.svg';
import vars from '../vars.json';
import createIconSelect from '../createIconSelect';

export const RELATIONSHIPS = [
  { color: vars.colors.orangeyellow, Icon: Moving, name: 'moving' },
  { color: vars.colors.green, Icon: Distant, name: 'distant' },
  { color: vars.colors.pink, Icon: Connected, name: 'connected' },
  { color: vars.colors.purple, Icon: Alone, name: 'alone' },
  { color: vars.colors.red, Icon: Many, name: 'many' },
  { color: vars.colors.grey, Icon: Dead, name: 'dead' },
  { color: vars.colors.lightgreen, Icon: Chilling, name: 'chilling' },
  { color: vars.colors.orange, Icon: Excited, name: 'excited' },
  { color: vars.colors.skyblue, Icon: Calm, name: 'calm' },
];

export const RelationShipSelect = createIconSelect(
  RELATIONSHIPS,
  'relationship',
);
