import 'firebase/auth';
import { useMemo, useState, useEffect } from 'react';
import useFirebase from './useFirebase';
import { useAuthState } from 'react-firebase-hooks/auth';

function useIdToken(user) {
  const [state, setState] = useState({
    token: null,
    error: null,
  });

  useEffect(() => {
    if (!user) {
      return;
    }

    let canceled = false;

    user
      .getIdTokenResult()
      .then((token) => {
        if (!canceled) {
          setState({ token, error: null });
        }
      })
      .catch((error) => {
        if (!canceled) {
          setState({ token: null, error });
        }
      });

    return () => (canceled = true);
  }, [user]);

  return [state.token, user && !state.token && !state.error, state.error];
}

function useDecoratedUser(user) {
  const [idToken, loading, error] = useIdToken(user);
  return [
    useMemo(
      () =>
        user &&
        idToken && {
          uid: user.uid,
          email: user.email,
          isAdmin: idToken.claims.admin,
        },
      [user, idToken],
    ),
    loading,
    error,
  ];
}

export default function useUser() {
  const firebase = useFirebase();
  const [user, authLoading, error] = useAuthState(firebase.auth());
  const [decoratedUser, loading, userError] = useDecoratedUser(user);

  return [decoratedUser, authLoading || loading, error || userError];
}
