import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import vars from './components/vars';
import { FirebaseProvider, AdminRoute } from './lib';
import { QueryList, MusicFinder, User, Login, Logout } from './views';
import { Loading } from './components';

const ManageMp3s = lazy(() => import('./views/ManageMp3s'));

const StyledApp = styled.div`
  min-height: 100vh;

  overflow: auto;
  background: ${vars.colors.darkGrey};
  color: white;
`;

export const Main = styled.main`
  max-width: 40em;

  margin: 0 auto;
  padding: 2em;
`;

export default function App() {
  return (
    <FirebaseProvider>
      <StyledApp>
        <Router>
          <User />
          <Main>
            <Suspense fallback={<Loading />}>
              <Switch>
                <Route path="/find-music/:id" component={MusicFinder} />
                <Route path="/login/:target?" component={Login} />
                <Route path="/logout" component={Logout} />
                <AdminRoute path="/manage-mp3" component={ManageMp3s} />
                <AdminRoute exact path="/" component={QueryList} />
                <Route path="*" render={() => <h1>Not Found</h1>} />
              </Switch>
            </Suspense>
          </Main>
        </Router>
      </StyledApp>
    </FirebaseProvider>
  );
}
