import React from 'react';

import styled from 'styled-components';

import { ReactComponent as LineIllu } from './shapes/Line.svg';
import { ReactComponent as OvalIllu } from './shapes/Oval.svg';
import { ReactComponent as PolygonIllu } from './shapes/Polygon.svg';
import { ReactComponent as RectangleIllu } from './shapes/Rectangle.svg';
import { ReactComponent as StarIllu } from './shapes/Star.svg';
import { ReactComponent as TriangleIllu } from './shapes/Triangle.svg';

const StyledShapeSelect = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
  justify-items: center;
`;

const StyledLabel = styled.label`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
`;

const StyledLabelContent = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  border: 2px solid white;
  border-radius: 8px;

  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.5;
  transition: opacity 120ms ease-out;
`;

const ShapeInput = styled.input`
  position: absolute;
  opacity: 0;
  &:checked ~ ${StyledLabelContent} {
    opacity: 1;
  }
`;

const StyledShape = styled.div`
  display: flex;
  width: 80px;
  height: 80px;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: 100%;
  }
`;

const ShapeLabel = ({ value, checked, children, onChange }) => {
  return (
    <StyledLabel>
      <ShapeInput
        name="shape"
        value={value}
        checked={checked}
        onChange={onChange}
        type="radio"
      />
      <StyledLabelContent>{children}</StyledLabelContent>
    </StyledLabel>
  );
};

const SHAPE_MATCH = {
  oval: OvalIllu,
  rect: RectangleIllu,
  triangle: TriangleIllu,
  octagon: PolygonIllu,
  star: StarIllu,
  line: LineIllu,
};

const SHAPES = ['oval', 'rect', 'triangle', 'octagon', 'star', 'line'];

export const Shape = ({ type }) => {
  const Ele = SHAPE_MATCH[type];
  return <Ele></Ele>;
};

export const ShapeSelect = ({ value, onChange }) => {
  return (
    <StyledShapeSelect>
      {SHAPES.map((illu, index) => {
        return (
          <ShapeLabel
            value={illu}
            checked={illu === value}
            onChange={onChange}
            key={index}
          >
            <StyledShape>
              <Shape className="illustration" type={illu} />
            </StyledShape>
          </ShapeLabel>
        );
      })}
    </StyledShapeSelect>
  );
};
